exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-klyuchovi-tendenciyi-seo-index-tsx": () => import("./../../../src/pages/blog/klyuchovi-tendenciyi-seo/index.tsx" /* webpackChunkName: "component---src-pages-blog-klyuchovi-tendenciyi-seo-index-tsx" */),
  "component---src-pages-blog-prosuvannya-novih-sajtiv-index-tsx": () => import("./../../../src/pages/blog/prosuvannya-novih-sajtiv/index.tsx" /* webpackChunkName: "component---src-pages-blog-prosuvannya-novih-sajtiv-index-tsx" */),
  "component---src-pages-blog-seo-audit-sajtu-6-etapiv-index-tsx": () => import("./../../../src/pages/blog/seo-audit-sajtu-6-etapiv/index.tsx" /* webpackChunkName: "component---src-pages-blog-seo-audit-sajtu-6-etapiv-index-tsx" */),
  "component---src-pages-blog-sho-take-linkbilding-index-tsx": () => import("./../../../src/pages/blog/sho-take-linkbilding/index.tsx" /* webpackChunkName: "component---src-pages-blog-sho-take-linkbilding-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-seo-index-tsx": () => import("./../../../src/pages/seo/index.tsx" /* webpackChunkName: "component---src-pages-seo-index-tsx" */),
  "component---src-pages-seo-prosuvannya-dnipro-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-dnipro/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-dnipro-index-tsx" */),
  "component---src-pages-seo-prosuvannya-kharkiv-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-kharkiv/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-kharkiv-index-tsx" */),
  "component---src-pages-seo-prosuvannya-kryvyi-rih-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-kryvyiRih/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-kryvyi-rih-index-tsx" */),
  "component---src-pages-seo-prosuvannya-kyiv-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-kyiv/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-kyiv-index-tsx" */),
  "component---src-pages-seo-prosuvannya-lviv-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-lviv/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-lviv-index-tsx" */),
  "component---src-pages-seo-prosuvannya-odesa-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-odesa/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-odesa-index-tsx" */),
  "component---src-pages-seo-prosuvannya-sumy-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-sumy/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-sumy-index-tsx" */),
  "component---src-pages-seo-prosuvannya-uzhhorod-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-uzhhorod/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-uzhhorod-index-tsx" */),
  "component---src-pages-seo-prosuvannya-vinnitsa-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-vinnitsa/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-vinnitsa-index-tsx" */),
  "component---src-pages-seo-prosuvannya-zaporizhzhia-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-zaporizhzhia/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-zaporizhzhia-index-tsx" */),
  "component---src-pages-seo-prosuvannya-zhytomyr-index-tsx": () => import("./../../../src/pages/seo-prosuvannya-zhytomyr/index.tsx" /* webpackChunkName: "component---src-pages-seo-prosuvannya-zhytomyr-index-tsx" */),
  "component---src-pages-ugoda-koristuvacha-index-tsx": () => import("./../../../src/pages/ugoda-koristuvacha/index.tsx" /* webpackChunkName: "component---src-pages-ugoda-koristuvacha-index-tsx" */),
  "component---src-pages-web-rozrobka-index-tsx": () => import("./../../../src/pages/web-rozrobka/index.tsx" /* webpackChunkName: "component---src-pages-web-rozrobka-index-tsx" */)
}

